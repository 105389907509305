import { FC, Fragment } from 'react';

import logger from '@common/log';
import { useChat } from '@components/chat/useChat';
import RichText from '@components/RichText/RichText';
import { StatusIndicator } from '@components/ui/StatusIndicator/StatusIndicator';
import { useLinkComponent } from '@link';
import { wrap } from '@sitecore/common';
import {
  ContactCustomerService3F500BF302B546F4B51EE908F8B72BBE as ContactCustomerServiceItemList,
  ContactCustomerService,
  ContactCustomerServiceRendering,
  ContentComponentsContactMethodsItemsChatLinkField,
  TextField,
} from '@sitecore/types/ContactCustomerService';
import {
  Badge,
  Box,
  Button,
  ButtonLink,
  Card,
  Divider,
  Expandable,
  Grid,
  Heading,
  Image,
  Stack,
  Stretch,
  Text,
  TextLink,
} from '@sparky';
import { MobileIcon } from '@sparky/icons';
import { FeedbackColors, HeadingProps } from '@sparky/types';

type ColorTypes = 'success' | 'warning' | 'error';

type Triggerprops = ContactExpandableTriggerProps & {
  headingLevel: HeadingProps['as'];
};

interface ContactExpandableContentProps {
  items?: ContactCustomerServiceItemList;
  openingHoursTitle?: TextField;
  openingHoursContent?: TextField;
  isBadge: boolean;
  compactView: boolean;
}

const ContactExpandableTrigger: FC<Triggerprops> = ({
  title,
  availabilityColor,
  availabilityText,
  headingLevel,
  isBadge,
  openingHoursAvailabilityText,
}) => {
  return (
    <Expandable.Trigger label={typeof title === 'string' ? title : ''}>
      <Stack gap="2">
        <Heading as={headingLevel} size={{ initial: '3XS', md: 'XS' }}>
          {title}
        </Heading>
        {!isBadge && availabilityText && (
          <StatusIndicator color={availabilityColor}>{availabilityText}</StatusIndicator>
        )}
        {isBadge && openingHoursAvailabilityText?.value && <Text as="p">{openingHoursAvailabilityText?.value}</Text>}
      </Stack>
    </Expandable.Trigger>
  );
};

const ChatButton: FC<{
  chatLink: ContentComponentsContactMethodsItemsChatLinkField;
}> = ({ chatLink }) => {
  try {
    const [mount] = useChat();
    return (
      <Button
        action="primary"
        size="regular"
        tone="onLight"
        type="button"
        onClick={() => mount({ visibility: 'open', topic: chatLink.fields.chatTopic?.value })}>
        {chatLink.fields.displayText?.value}
      </Button>
    );
    //Saveguard against accidental usage outside ChatProvider
  } catch (e) {
    logger.error('NtdDKV', e instanceof Error ? e.message : 'ChatButton could not render');
    return null;
  }
};

export interface ContactExpandableTriggerProps {
  title: string;
  availabilityColor: Extract<keyof FeedbackColors, 'feedbackSuccess' | 'feedbackWarning' | 'feedbackError'>;
  availabilityText: string;
  isBadge: boolean;
  openingHoursAvailabilityText?: TextField;
}

type ContentProps = ContactExpandableContentProps & {
  headingLevel: HeadingProps['as'];
};

const ContactExpandableContent: FC<ContentProps> = ({
  items,
  headingLevel,
  openingHoursTitle,
  openingHoursContent,
  isBadge,
  compactView,
}) => {
  const Link = useLinkComponent();

  return (
    <Expandable.Content>
      <Box paddingTop={!isBadge ? '12' : '6'} paddingBottom="4">
        <Stack gap="6">
          <Stack>
            {items?.map((item, index) => {
              return (
                <Fragment key={index}>
                  <Stack gap="2">
                    {item.fields.title?.value && (
                      <Heading as={headingLevel} size={{ initial: '3XS', md: '2XS' }}>
                        {wrap(item.fields.title)}
                      </Heading>
                    )}
                    {item.fields.content?.value && (
                      <Text>{wrap(item.fields.content, <RichText html={item.fields.content?.value} />)}</Text>
                    )}
                    <Box paddingTop={compactView ? '0' : '4'} paddingBottom={index !== items.length - 1 ? '6' : '0'}>
                      <Stack gap="4" alignX="start">
                        {item.fields.chatLink?.fields.chatTopic?.value &&
                          item.fields.chatLink?.fields.displayText?.value && (
                            <ChatButton chatLink={item.fields.chatLink} />
                          )}
                        {item.fields.buttonLink?.value?.text && (
                          <Link
                            editable={item.fields.buttonLink.editable}
                            linkType={item.fields.buttonLink.value.linktype}
                            linkValue={item.fields.buttonLink.value}>
                            <ButtonLink>{item.fields.buttonLink.value.text}</ButtonLink>
                          </Link>
                        )}
                        {item?.fields?.link?.value?.text && (
                          <TextLink
                            target={item.fields.link.value.target}
                            href={item.fields.link.value.href}
                            emphasis="high">
                            {item?.fields?.link?.value?.text}
                          </TextLink>
                        )}
                        {item.fields.phoneNumber?.value && (
                          <Stack direction="row" gap="1">
                            <MobileIcon />
                            <Text size={{ initial: 'BodyM', md: 'BodyL' }} weight="bold">
                              <TextLink href={`tel:${wrap(item.fields.phoneNumber)}`} emphasis="medium">
                                {wrap(item.fields.phoneNumber)}
                              </TextLink>
                            </Text>
                          </Stack>
                        )}
                      </Stack>
                    </Box>
                  </Stack>

                  {items.length - 1 !== index && !compactView ? (
                    <Box paddingTop="2" paddingBottom="8">
                      <Divider />
                    </Box>
                  ) : null}
                </Fragment>
              );
            })}
          </Stack>

          {((openingHoursTitle?.value && openingHoursContent?.value) ||
            openingHoursTitle?.editable ||
            openingHoursContent?.editable) && (
            <Stack gap="2">
              <Heading as={headingLevel} size={{ initial: '3XS', md: '2XS' }}>
                {wrap(openingHoursTitle)}
              </Heading>
              <Text>{wrap(openingHoursContent, <RichText html={openingHoursContent?.value} />)}</Text>
            </Stack>
          )}
        </Stack>
      </Box>
    </Expandable.Content>
  );
};

const ContactExpandable: FC<{
  item: ContactCustomerService[0];
  headingLevel: HeadingProps['as'];
  isBadge: boolean;
  compactView: boolean;
}> = ({ item, headingLevel, isBadge, compactView }) => {
  //@ts-ignore these props always match
  const triggerHeadingLevel: HeadingProps['as'] = `h${Math.min(5, +headingLevel.charAt(1) + 1)}`;
  //@ts-ignore these props always match
  const contentHeadingLevel: HeadingProps['as'] = `h${Math.min(6, +headingLevel.charAt(1) + 2)}`;
  return (
    <Expandable defaultOpen={item.fields.startsExpanded?.value || false}>
      <Stack>
        <ContactExpandableTrigger
          title={wrap(item.fields.title)}
          availabilityColor={
            (item.fields.availabilityColor?.value as ContactExpandableTriggerProps['availabilityColor']) ||
            'feedbackError'
          }
          availabilityText={wrap(item.fields.availabilityText)}
          headingLevel={triggerHeadingLevel}
          isBadge={isBadge}
          openingHoursAvailabilityText={item.fields.openingHoursAvailabilityText}
        />
        <ContactExpandableContent
          headingLevel={contentHeadingLevel}
          items={item.fields.items}
          openingHoursTitle={item.fields.openingHoursTitle}
          openingHoursContent={item.fields.openingHoursContent}
          isBadge={isBadge}
          compactView={compactView}
        />
      </Stack>
    </Expandable>
  );
};

const ContactCustomerServiceComponent: FC<ContactCustomerServiceRendering> = ({ fields, params }) => {
  //@ts-ignore these props always match
  const headingLevel: HeadingProps['as'] = params?.headingLevel
    ? `h${Math.min(4, +params.headingLevel.charAt(1))}`
    : 'h2';

  const hasImage = !!fields.image?.value?.src;
  const gridItemColumn = hasImage ? { initial: '1/-1', md: '1/2' } : { initial: '1/-1', md: '1/-1', lg: '2/4' };
  const isBadge = params?.RenderVariant === 'Badge';
  const compactView = params?.CompactViewmode === '1';

  return (
    <Grid gap="6" columns={hasImage ? 2 : 4}>
      <Grid.Item gridColumn={gridItemColumn}>
        <Box paddingTop={{ initial: '8', md: '16' }} paddingBottom={{ md: '16' }}>
          <Box paddingBottom={{ initial: '6', md: '8' }}>
            <Heading as={headingLevel} size={{ initial: 'XS', md: 'M' }} color="inherit">
              {wrap(fields.title)}
            </Heading>
          </Box>
          <Card>
            {fields.items?.map((item, index) => {
              const color = item.fields.availabilityColor?.value;

              let badgeColor = 'success' as ColorTypes;
              switch (color) {
                case 'feedbackBackgroundWarning':
                  badgeColor = 'warning';
                  break;
                case 'feedbackBackgroundError':
                  badgeColor = 'error';
                  break;
              }
              return (
                <Fragment key={index}>
                  <Box paddingX="8" paddingY="6">
                    <Stack gap="4">
                      {isBadge && item.fields.availabilityText?.value && (
                        <Box>
                          <Badge size="M" color={badgeColor}>
                            {item.fields.availabilityText.value}
                          </Badge>
                        </Box>
                      )}
                      <ContactExpandable
                        item={item}
                        headingLevel={headingLevel}
                        isBadge={isBadge}
                        compactView={compactView}
                      />
                    </Stack>
                  </Box>
                  {fields.items.length - 1 !== index ? <Divider /> : null}
                </Fragment>
              );
            })}
          </Card>
        </Box>
      </Grid.Item>
      {hasImage && (
        <Grid.Item gridColumn={{ initial: '1/-1', md: '2/3' }}>
          <Stretch>
            <Box>
              <Stretch>
                <Stack alignX={{ initial: 'center', md: 'end' }}>
                  <Image src={fields.image.value.src} alt={fields.image.value.alt} maxWidth="100%" />
                </Stack>
              </Stretch>
            </Box>
          </Stretch>
        </Grid.Item>
      )}
    </Grid>
  );
};

export default ContactCustomerServiceComponent;
